import { Document_Status } from "generated/graphql";
import { atom } from "recoil";
import { LANG_CODE } from "../constants";
import { originalDocumentData } from "./originalDocumentData";
import { CategoriesType, CopyrightType, DisplayType, FileConfig, ImageType, MemoType } from "./type";

export const displayState = atom<DisplayType>({
  key: 'display',
  default: {
    isEdit: false,
    isDisp: false,
  },
});

export const idState = atom<string>({
  key: 'id',
  default: undefined,
});

export const assetIdState = atom<number|null>({
  key: 'assetId',
  default: undefined,
});


export const brandState = atom<number|null>({
  key: 'brand',
  default: undefined,
});

export const languageState = atom<LANG_CODE>({
  key: 'language',
  default: LANG_CODE.KO,
});

export const originalLanguageState = atom<LANG_CODE>({
  key: 'originalLanguage',
  default: LANG_CODE.KO,
});

export const statusState = atom<Document_Status>({
  key: 'documentStatus',
  default: undefined,
});

export const titleState = atom<string|null>({
  key: 'title',
  default: undefined,
});

export const contentHeadState = atom<string|null>({
  key: 'contentHead',
  default: undefined,
});

export const contentBodyState = atom<string|null>({
  key: 'contentBody',
  default: undefined,
});

export const fileState = atom<FileConfig>({
  key: 'file',
  default: {
    filePath: undefined,
    fileName: undefined,
    fileComponents: undefined,
    fileUpdates: undefined,
  },
});

export const extensionState = atom<Array<number>>({
  key: 'extension',
  default: [],
});

export const applicationsState = atom<Array<number>>({
  key: 'applications',
  default: [],
});

export const mainImageState = atom<ImageType>({
  key: 'mainImage',
  default: {
    path: undefined,
    name: undefined,
    cropInfo: {
      imageOriginX: undefined,
      imageOriginY: undefined,
      imageWidth: undefined,
      imageHeight: undefined,
    },
    isComplete: false,
  },
});

export const subImageState = atom<ImageType>({
  key: 'subImage',
  default: {
    path: undefined,
    name: undefined,
    cropInfo: {
      imageOriginX: undefined,
      imageOriginY: undefined,
      imageWidth: undefined,
      imageHeight: undefined,
    },
    isComplete: false,
  },
});

export const categoriesState = atom<CategoriesType>({
  key: 'categories',
  default: {
    genre: {
      categoryBranch: undefined,
    },
    theme: {
      categoryBranch: undefined,
    },
    game: {
      categoryBranch: undefined,
    }
  },
});

export const priceState = atom<number|null>({
  key: 'price',
  default: undefined,
});

export const keywordsState = atom<Array<string>>({
  key: 'keywords',
  default: [],
});


export const memoState = atom<Array<MemoType>>({
  key: 'memo',
  default: [],
});

export const copyrightState = atom<CopyrightType>({
  key: 'copyright',
  default: {
    isOriginal: true,
    commercialSources: undefined,
    warehouseSources: undefined,
    hasRealLogo: undefined,
    isErrorWarehouseCopyright: false,
    isErrorFreeCopyright: false,
    isErrorAgree: false,
  },
});

export const createdState = atom<Date>({
  key: 'created',
  default: undefined,
});

export const requestedState = atom<Date>({
  key: 'requested',
  default: undefined,
});

export const completedState = atom<Date>({
  key: 'completed',
  default: undefined,
});

export const versionState = atom<number>({
  key: 'version',
  default: undefined,
});

export const updateRequestState = atom<string>({
  key: 'updateRequest',
  default: undefined,
});

export const priceChangedState = atom<Date>({
  key: 'priceChanged',
  default: undefined,
});

export const selectedFieldsState = atom<Array<string>>({
  key: 'selectedFields',
  default: [],
});

export const isAdultOnlyState = atom<boolean>({
  key: 'isAdultOnly',
  default: false,
});

export const webtoonState = atom<boolean>({
  key: 'webtoon',
  default: false,
});

export const gameState = atom<boolean>({
  key: 'game',
  default: false,
});

export const messageState = atom<string>({
  key: 'message',
  default: undefined,
});

export { originalDocumentData, };
