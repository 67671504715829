import React, { useContext, useEffect, useRef, useState } from 'react';
import { Copyright, FixedMenu, Keywords, Model, Title, SalePrice, DocumentType, Reject } from './value';
import { Box as MuiBox, Typography } from '@mui/material';
import { Wrap } from './style';
import { AppContext } from 'app';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  LANG_CODE,
  NEW_STATUS_DRAFT,
  STATUS_COMPLETE,
  STATUS_COMPLETE_ON_OPEN,
  STATUS_DENIED,
  STATUS_IN_REVIEW,
  STATUS_IN_REVIEW_ON_OPEN,
  STATUS_OPEN,
  STATUS_REQUESTED,
  STATUS_REQUESTED_ON_OPEN,
  TranslateModalType,
} from './constants';
import Loading from '../../common/editor/Loading';
import { useCookies } from 'react-cookie';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { LanguageCodeEnum, useDocumentQuery } from 'generated/graphql';
import { Field } from 'acon-mui/components/Board';
import Langauge from './value/langauge';
import { color } from 'acon-mui/style';
import InformationCard from 'acon-mui/components/Board/InformationCard';
import { documentDataSelector } from './recoil/selectors';
import ImageForm from './value/Image';
import ProductDetails from './value/ProductDetails';
import { originalDocumentDataSelector } from './recoil/selectors/originalDocumentDataSelector';
import PreviewAconProduct from 'boards/PreviewAconProduct/PreviewAconProductModal';
import { Button } from 'acon-mui/components';

export default () => {
  const { userInfo, showConfirmMessage } = useContext(AppContext);
  const [documentData, setDocumentData] = useRecoilState(documentDataSelector);
  const setOriginalDocumentData = useSetRecoilState(originalDocumentDataSelector);

  const [cookie, setCookie] = useCookies();
  // 관리자 여부
  const { isAdmin, isAccept } = userInfo;

  // 번역도구
  const { t, i18n } = useTranslation();
  // URL 파생 정보
  const { type, docId, lang } = useParams<{
    type: string;
    docId: string;
    lang: string;
  }>();
  // history
  const history = useHistory();

  // 진행 여부
  const [isInProgress, setIsInProgress] = useState(false);

  const warehouseInput = useRef(null);
  const externalFreeSourceInput = useRef(null);
  const externalPaySourceInput = useRef(null);
  const agreeInput = useRef(null);

  // 오픈 여부
  const isOpen = type === STATUS_OPEN;

  // 현재 항목 데이터
  const { data, loading, error, refetch } = useDocumentQuery({
    variables: {
      documentId: docId,
    },
    fetchPolicy: 'no-cache',
    // 문서 ID 또는 언어가 지정되지 않으면 항목을 가져오지 않음
    skip: !docId || !lang,
  });
  const isLoading = loading || error;
  useEffect(() => {
    /* 유효성 검증 */
    if (!isAdmin && isOpen) {
      history.push('/'); // 사용자가 오픈된 문서에 접근했을 경우 루트 페이지로 이동
    }
    if (
      // 작가의 경우, 문서값이 존재하지 않거나 쿠키가 존재하여 해당 쿠키값이 현재 문서언어와 다를경우
      !isAdmin &&
      !docId &&
      cookie.rememberLanguage &&
      cookie.rememberLanguage !== lang
    ) {
      history.push(`/goods/write/${cookie.rememberLanguage}`);
    }
    if (!data) {
      // 데이터가 존재하지 않는 경우
      setDocumentData({
        brand: userInfo.brandId as number,
        language: lang.toUpperCase() as unknown as LANG_CODE,
        status: NEW_STATUS_DRAFT,
      });
      return; // 데이터가 존재하지 않으면 종료.
    }
    if (!data.document) {
      return;
    }

    const {
      id,
      brandId,
      language,
      status,
      title,
      contentHead,
      contentBody,
      price,
      mainCoordination,
      mainImage,
      mainOrigin,
      subCoordination,
      subImage,
      subOrigin,
      fileName,
      filePath,
      categories,
      copyright,
      keywords,
      priceChanged,
      isAdultOnly,
      webtoon,
      game,
      message,
    } = data.document;

    // 로딩/에러가 아니고, 데이터가 정상적으로 확보가 된 경우
    if (data && !loading && !error) {
      let mainCropInfo = {
        imageOriginX: mainCoordination?.x,
        imageOriginY: mainCoordination?.y,
        imageWidth: mainCoordination?.width,
        imageHeight: mainCoordination?.height,
      };
      let subCropInfo = {
        imageOriginX: subCoordination?.x,
        imageOriginY: subCoordination?.y,
        imageWidth: subCoordination?.width,
        imageHeight: subCoordination?.height,
      };

      if (!mainOrigin && !mainCoordination) {
        mainCropInfo = {
          imageOriginX: 0,
          imageOriginY: 0,
          imageWidth: 1,
          imageHeight: 1,
        };
      }
      if (!subOrigin && !subCoordination) {
        subCropInfo = {
          imageOriginX: 0,
          imageOriginY: 0,
          imageWidth: 1,
          imageHeight: 1,
        };
      }

      let newData = {
        id,
        brand: brandId as number,
        language: language as unknown as LANG_CODE,
        ...(status && { status }),
        ...(title && { title }),
        ...(contentHead && { contentHead }),
        ...(contentBody && { contentBody }),
        ...(typeof price === 'number' && { price }),
        mainImage: {
          path: mainImage,
          origin: mainOrigin || mainImage,
          ...((mainOrigin || mainImage) && {
            cropInfo: mainCropInfo,
          }),
          ...(mainImage && { isComplete: true }),
        },
        subImage: {
          path: subImage,
          origin: subOrigin || subImage,
          ...((subOrigin || subImage) && {
            cropInfo: subCropInfo,
          }),
          ...(subImage && { isComplete: true }),
        },
        file: {
          fileName: fileName,
          filePath: filePath,
        },
        categories: {
          genre: {
            categoryBranch: categories?.genre?.categoryBranch,
          },
          theme: {
            categoryBranch: categories?.theme?.categoryBranch,
          },
          game: {
            categoryBranch: categories?.game?.categoryBranch,
          },
        },
        ...(copyright && {
          copyright: {
            ...copyright,
            isOriginal: copyright.isOriginal !== null && typeof copyright.isOriginal !== 'undefined' ? copyright.isOriginal : false,
          },
        }),
        ...(keywords && { keywords }),
        priceChanged,
        isAdultOnly,
        webtoon,
        game,
        message,
      };
      delete newData.copyright.__typename;

      setDocumentData(newData);
      setOriginalDocumentData(newData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, data, lang]);

  useEffect(() => {
    // 관리자인 경우 admin으로 redirect
    if (isAdmin) {
      const toPushUrl = `https://${process.env.NODE_ENV === 'production' ? '' : 'release'}admin.acon3d.com/product/write/${lang}/${docId}`;
      history.push(toPushUrl);
      return;
    }

    // 일반 사용자가 문서 상태가 요청됨 인 경우
    if (
      !isAdmin &&
      (documentData.status === STATUS_REQUESTED ||
        documentData.status === STATUS_REQUESTED_ON_OPEN ||
        documentData.status === STATUS_IN_REVIEW ||
        documentData.status === STATUS_IN_REVIEW_ON_OPEN)
    ) {
      const isEdit = documentData.status === STATUS_REQUESTED_ON_OPEN || documentData.status === STATUS_IN_REVIEW_ON_OPEN;
      // 안내 메세지 표시
      showConfirmMessage(
        isEdit ? t('requestReviewCompletedModalTitle3') : t('requestReviewCompletedModalTitle'),
        isEdit ? t('requestReviewCompletedModalContents3') : t('requestReviewCompletedModalContents'),
        () => {
          // 메인 홈으로 이동
          history.push('/');
        },
        {
          cancelText: t('document.viewProduct'),
          confirmText: t('backToList'),
        },
      );
      // 종료
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, documentData.status]);

  const [previewModal, setPreviewModal] = useState({
    title: '',
    contents: '',
    isShow: false,
  });
  // 번역 모달 타입: 컨텐츠 번역/파일 번역
  const [translateModalType, setTranslateModalType] = useState<TranslateModalType>(TranslateModalType.CONTENT);

  // 미리보기 모달 표시여부 설정하기 메소드
  const setIsShowPreviewModal = (isShow) => {
    setPreviewModal({
      ...previewModal,
      isShow,
    });
  };

  const PageTitle = () => {
    switch (documentData.status) {
      case STATUS_COMPLETE:
        return documentData.assetId ? (
          <>
            [{documentData.assetId}] {t('document.product')}
          </>
        ) : (
          <>{t('Home.Detail')}</>
        );
      case STATUS_COMPLETE_ON_OPEN:
        return documentData.assetId ? (
          <>
            [{documentData.assetId}] {t('document.product')}
          </>
        ) : (
          <>{t('Home.Detail')}</>
        );
    }
    if (documentData.isEdit) {
      return <>{t('document.ProductUpdate')}</>;
    }
    switch (documentData.status) {
      case STATUS_REQUESTED:
        return <>{t('document.ProductInReview')}</>;
      case STATUS_IN_REVIEW:
        return <>{t('document.ProductInReview')}</>;
      case STATUS_DENIED:
        return <>{t('document.DeniedProduct')}</>;
      default:
        return <>{t('document.NewProductRegistration')}</>;
    }
  };

  // 로딩중인 경우
  if (loading && !data) return <Loading empty={true} />;
  return (
    <>
      <Reject />
      {data && <PreviewAconProduct docId={data.document.id} lang={lang as LanguageCodeEnum} show={previewModal.isShow} close={() => setIsShowPreviewModal(false)} />}
      <Wrap fullWidth>
        <Field
          informationCards={
            <Button
              component="a"
              href={i18n.language === 'ko' ? 'https://partnerguide.acon3d.com/' : 'https://partnerguide.acon3d.com/en/'}
              target="_blank"
              rel="noopener noreferrer"
              colorTheme="primary"
              variant="contained"
              marginLeft="auto"
              width="fit-content"
              height="42px"
              borderRadius="4px"
            >
              <Typography color="white">{t('document.GuideButton')}</Typography>
            </Button>
          }
        >
          <MuiBox display="flex" alignItems="center">
            <Typography mr="auto" color={color.text.primary} fontWeight="700" fontSize="24px" lineHeight="36px">
              <PageTitle />
            </Typography>
            <Langauge isAdmin={isAdmin} />
          </MuiBox>

          {documentData.isEdit && <InformationCard marginTop="24px">{t('document.ProductUpdateInformation')}</InformationCard>}
        </Field>

        <FixedMenu
          {...{
            refetch,
            isInProgress: isInProgress || isLoading,
            translateModalType,
            inputs: {
              warehouseInput,
              externalFreeSourceInput,
              externalPaySourceInput,
              agreeInput,
            },
            showPreviewModal: () => {
              setIsShowPreviewModal(true);
            },
          }}
        />

        {/* 상품 구분 */}
        <DocumentType />

        {/* 제목 필드 */}
        <Title />

        {/* 가격 */}
        <Field className="hubWrite_field_price">
          <SalePrice isAdmin={isAdmin} />
        </Field>

        <Field
          className="hubWrite_field_file"
          informationCards={
            <>
              <InformationCard type="warning">{t('document.file.notice')}</InformationCard>
              <InformationCard marginTop="20px">{t('document.file.information')}</InformationCard>
            </>
          }
        >
          {/* 작품 파일 */}
          <Model setIsInProgress={setIsInProgress} isAccept={isAccept} isAdmin={isAdmin} />
        </Field>

        {/* 저작권 */}
        <Field className="hubWrite_field_copyright">
          <Copyright
            inputs={{
              warehouseInput,
              externalFreeSourceInput,
              externalPaySourceInput,
              agreeInput,
            }}
          />
        </Field>

        <ImageForm />
        <ProductDetails />

        <Field className="hubWrite_field_keywords" informationCards={<InformationCard type="notice">{t('document.keywords.notice')}</InformationCard>}>
          <Keywords />
        </Field>
      </Wrap>
    </>
  );
};
